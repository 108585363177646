import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
import "./less/index.less";
import { Collapse } from "antd";
const { Panel } = Collapse;

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

class Policy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlayed: 0,
    };
  }
  componentDidMount() {}
  componentWillUnmount() {}
  render() {
    return (
      <div className="legal-page">
        <div className="legal-title">Starpower Privacy Policy</div>
        <Collapse defaultActiveKey={["1"]} accordion className="legal-collapse">
          <Panel header="Introduction" key="1" className="legal-panel">
            <p className="legal-panel-content">
              Welcome to Starpower! Leveraging technologies like IoT,
              blockchain, and artificial intelligence, we connect and dispatch
              energy devices including air conditioners, water heaters, and
              storage batteries, empowering applications such as virtual power
              plants. We are committed to delivering reliable and secure
              services while respecting and protecting your privacy. This
              Privacy Policy is designed to help you understand how we collect,
              use, share, and protect the information you provide to us when
              using Starpower services.
            </p>
          </Panel>
          <Panel
            header="Information We Collect"
            key="2"
            className="legal-panel"
          >
            <p>
              <strong>1. Information You Provide</strong>
              <br />
              <ul>
                <li>
                  Account Registration Information: Such as your email address,
                  username, etc.
                </li>
                <li>
                  Device Information: Information about the energy devices you
                  wish to connect to the Starpower network, including but not
                  limited to model, location, energy consumption data, etc.
                </li>
              </ul>
              <strong>2. Automatically Collected Information</strong>
              <br />
              <ul>
                <li>
                  Device and Access Logs: We automatically log information when
                  you use Starpower services, such as IP address, access times,
                  browser type, and operating system.
                </li>
                <li>
                  Interaction Data: Data generated from your interactions with
                  the Starpower network, including energy dispatch feedback,
                  $STAR rewards collection, etc.
                </li>
              </ul>
            </p>
          </Panel>
          <Panel
            header="How We Use Your Information"
            key="3"
            className="legal-panel"
          >
            <p>
              We may use the information collected for the following purposes:
              <br />
              <ul>
                <li>To provide, maintain, and improve Starpower services;</li>
                <li>
                  To analyze and monitor service usage to enhance performance
                  and user experience;
                </li>
                <li>
                  To communicate with you, including providing customer support,
                  sending updates, and security alerts;
                </li>
                <li>
                  For reward distribution, such as allocating $STAR rewards
                  based on the contribution of your devices.
                </li>
              </ul>
            </p>
          </Panel>
          <Panel
            header="Sharing and Disclosure of Information"
            key="4"
            className="legal-panel"
          >
            <p>
              <ul>
                <li>
                  Partners and Third-Party Service Providers: We may share
                  necessary information with partners and third-party service
                  providers who support the technology and services of
                  Starpower.
                </li>
                <li>
                  Legal Requirements: We may be required to disclose your
                  information if required by law, court order, or government
                  request.
                </li>
                <li>
                  User Consent: We will obtain your explicit consent before
                  sharing your information in scenarios other than those
                  mentioned above.
                </li>
              </ul>
            </p>
          </Panel>
          <Panel
            header="Data Security"
            key="5"
            className="legal-panel"
          >
            <p>We implement industry-standard security measures to protect your information against unauthorized access, disclosure, alteration, or destruction. This includes data encryption, security protocols, and physical security measures.</p>
          </Panel>
          <Panel
            header="Your Rights"
            key="6"
            className="legal-panel"
          >
            <p>You have the right to access, correct, or delete your personal information. If you wish to exercise these rights or have any questions about this Privacy Policy, please contact us at support@starpower.world.</p>
          </Panel>
          <Panel
            header="Updates and Changes"
            key="7"
            className="legal-panel"
          >
            <p>We may update this Privacy Policy from time to time. We encourage users to regularly review this page for the latest privacy practices. Changes to this policy will be updated on this page and may also be notified via email or service notifications.</p>
          </Panel>
          <Panel
            header="Contact Us"
            key="8"
            className="legal-panel"
          >
            <p>If you have any questions about this Privacy Policy, please contact us through the Email: support@starpower.world</p>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default Policy;
