/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-02 07:55:28
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 02:18:06
 */
import React, { Component } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { enquireScreen } from "enquire-js"
import Home from "./Home"
import ResetPwd from "./ResetPwd"
import Product from "./Product"
import Header from './common/Header';
import Footer from './common/Footer';
import Docs from './Docs';
import Wallet from './Wallet';
import Service from './Legal/service';
import Policy from './Legal/policy';

import {
  Nav00DataSource,
} from './common/data.source.js';

let isMobile
enquireScreen((b) => {
  isMobile = b
})

let prevScrollPos = window.pageYOffset; // 初始滚动位置

// const eventBus = new EventEmitter();

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
      isAnimationLoaded: false
    }
  }
  componentDidMount() {
    // Mobile screen;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })
    // if (!isMobile) {
    //   window.addEventListener("resize", this.handleResize)
    // }
    let element = document.getElementsByClassName('home-page-wrapper')[0];
    element.classList.remove('opacity');
    document.body.style.backgroundColor = '#000'
    window.addEventListener('scroll', function() {
      const currentScrollPos = window.pageYOffset; // 当前滚动位置
      // 如果当前滚动位置大于之前的位置，则向下滚动
      if (currentScrollPos > prevScrollPos) {
        // console.log('向上滚动');
        let element = document.getElementsByClassName('home-page-wrapper')[0];
        element.classList.add('opacity');
      } else if (currentScrollPos < prevScrollPos) { // 如果当前滚动位置小于之前的位置，则向上滚动
        let element = document.getElementsByClassName('home-page-wrapper')[0];
        element.classList.remove('opacity');
      }
      prevScrollPos = currentScrollPos; // 更新滚动位置
    });
  }

  componentWillUnmount() {
    // if (!isMobile) {
    //   window.addEventListener("resize", this.handleResize)
    // }
  }

  // handleResize = () => {
  //   window.location.reload()
  // }

  onAnimationLoaded = () => {
    if (!this.state.isAnimationLoaded) {
      this.setState({
        isAnimationLoaded: true
      })
      document.body.style.backgroundColor = 'none'
    }
  }

  render() {
    let { isAnimationLoaded } = this.state
    return (
      <Router basename="/">
        <div>
          <Header dataSource={Nav00DataSource} isMobile={this.state.isMobile} />
          <Route path="/resetpassword" component={(props) => <ResetPwd {...props}/>} />
          <Route path="/product" component={(props) => <Product {...props}/>} />
          <Route path="/docs" component={(props) => <Docs {...props}/>} />
          <Route exact path="/" component={(props) => <Home {...props} />} />
          <Route path="/wallet" component={Wallet} />
          <Route path="/service" component={(props) => <Service {...props}/>} />
          <Route path="/policy" component={(props) => <Policy {...props}/>} />
          <Footer dataSource={Nav00DataSource} isMobile={this.state.isMobile} />
        </div>
      </Router>
    )
  }
}

export default App
