import React from "react";
import { enquireScreen } from "enquire-js";
import "./less/footer.less";
import axios from 'axios';
import { notification, Collapse } from 'antd';

const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
class Footer extends React.Component {
  static defaultProps = {
    className: "footer1",
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: true,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // this.props.eventBus.on('animationLoaded', () => {
    //   this.setState({
    //     show: true
    //   })
    // });
    let children = this.props.dataSource.Menu.children;
    children.splice(0, 0, {
      name: 'item-Company',
      className:
        'header0-item ant-menu-submenu ant-menu-submenu-horizontal lvu022uivs-editor_css',
      children: {
        href: '#',
        children: [
          {
            children: (
              <span>
                <p>Company</p>
              </span>
            ),
            name: 'text',
            className: 'lvu0aa9gxvr-editor_css',
          },
        ],
      },
      subItem: [
        {
          className: 'item-sub ant-menu-item lvtzvlvhhap-editor_css',
          children: {
            className: 'item-sub-item lvtzx1fse4-editor_css',
            children: [
              // {
              //   name: 'title~lvtzybmxwoe',
              //   className: 'lvtzynsln4s-editor_css',
              //   href: "https://starpower.gitbook.io/starpower-terms-of-service/",
              //   isRedirect:true,
              //   children: (
              //     <span>
              //       <p><span>Starpower</span></p>
              //     </span>
              //   ),
              // },
              {
                name: 'title~lvtzyi0i4l6',
                className: 'lvtzynsln4s-editor_css',
                href: "https://starpower.gitbook.io/starpower-lite-paper",
                // isRedirect:true,
                children: (
                  <span>
                    <p><span>Lite Paper</span></p>
                  </span>
                ),
              },
              {
                name: 'title~lvtzyi0i4l6',
                className: 'lvtzynsln4s-editor_css',
                href: "https://starpower.gitbook.io/https-starpower.gitbook.io-documentation/",
                // isRedirect:true,
                children: (
                  <span>
                    <p><span>Documentation</span></p>
                  </span>
                ),
              },
              {
                name: 'title~lvtzyi0i4l6',
                className: 'lvtzynsln4s-editor_css',
                href: "https://medium.com/@starpowerworld",
                children: (
                  <span>
                    <p><span>Blog</span></p>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    })
    let productMenu=children.find(item => item.name==='item1')
    productMenu.subItem = [
      {
        className: 'item-sub ant-menu-item lvtzvlvhhap-editor_css',
        children: {
          className: 'item-sub-item lvtzx1fse4-editor_css',
          children: [
            {
              name: 'title~lvtzybmxwoe',
              className: 'lvtzynsln4s-editor_css',
              href: "https://starpower-market.myshopify.com/products/starplug",
              isRedirect:true,
              children: (
                <span>
                  <p><span>Starplug</span></p>
                </span>
              ),
            },
            {
              name: 'title~lvtzyi0i4l6',
              className: 'lvtzynsln4s-editor_css',
              href: "https://starpower-market.myshopify.com/products/starbattery",
              isRedirect:true,
              children: (
                <span>
                  <p><span>Starbattery</span></p>
                </span>
              ),
            },
          ],
        },
      },
    ]
    children.push({
      name: 'item-Legal',
      className:
        'header0-item ant-menu-submenu ant-menu-submenu-horizontal lvu022uivs-editor_css',
      children: {
        href: '#',
        children: [
          {
            children: (
              <span>
                <p>Legal</p>
              </span>
            ),
            name: 'text',
            className: 'lvu0aa9gxvr-editor_css',
          },
        ],
      },
      subItem: [
        {
          className: 'item-sub ant-menu-item lvtzvlvhhap-editor_css',
          children: {
            className: 'item-sub-item lvtzx1fse4-editor_css',
            children: [
              {
                name: 'title~lvtzybmxwoe',
                className: 'lvtzynsln4s-editor_css',
                href: "/service",
                children: (
                  <span>
                    <p><span>Terms of Service</span></p>
                  </span>
                ),
              },
              {
                name: 'title~lvtzyi0i4l6',
                className: 'lvtzynsln4s-editor_css',
                href: "/policy",
                children: (
                  <span>
                    <p><span>Privacy Policy</span></p>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    })
    this.setState({
      show: true,
    })
  }

  componentWillUnmount() {
    // this.props.eventBus.off('animationLoaded');
  }

  submitInfo = () => {
    // get email
    let email = document.getElementById('email').value;
    let params = {
      email: email,
      name: null,
      type: "A"
    }
    axios.post('/starpower/api/visitor/regist', params).then((res) => {
      console.log(params);
      localStorage.setItem("info_id", res.data.data);
      notification.success({
        top: 70,
        duration: 1.5,
        placement: 'topLeft',
        message: 'Succress',
        description:
          'Submit Succress',
        onClick: () => {
          console.log('close');
        },
      })
      document.getElementById('email').value = '';
    }).catch((error) => {
      notification.error({
        top: 24,
        duration: 1.5,
        placement: 'topLeft',
        message: 'Error',
        description: error,
        onClick: () => {
          console.log('close');
        },
      })
    });
  }

  onLinkClick = (href, isRedirect) => {
    if (!href) return;
    if(isRedirect){
      window.location.href=href
    }else{
      window.open(href, "_blank");
    }
  }

  render() {
    const { dataSource } = this.props;
    const customPanelStyle = {
      color: '#fff',
      background: '#000',
      // borderRadius: 0,
      // marginBottom: 24,
      // border: 0,
      // overflow: 'hidden',
    };
    let navData = dataSource.Menu.children;
    // 去掉的菜单
    navData = navData.filter(item => !["item-Document", "item2", "item7", "item-wallet"].includes(item.name))
    const links = [
      {
        icon: require("../../assets/images/home/discord.svg"),
        href: "https://discord.gg/starpowernetwork",
        name: "Discord",
      },
      {
        icon: require("../../assets/images/home/telegram.svg"),
        href: "https://t.me/starpowernetwork",
        name: "Telegram",
      },
      {
        icon: require("../../assets/images/home/twitter.svg"),
        href: "https://twitter.com/starpowerworld",
        name: "Twitter",
      },
      {
        icon: require("../../assets/images/home/youtube.svg"),
        href: "https://www.youtube.com/@StarpowerWorld",
        name: "Youtube",
      },
    ];
    const { show } = this.state;
    return (
      show ?
      <div className="footer">
        <div className="footer-content">
          {/* <div className="footer-title">Join Starpower <span>Community</span></div>
          <div className="footer-Subscribe">
            <input id='email' className='centerSearch' type="text" placeholder='hi@starpower.world'></input>
            <button onClick={() => this.submitInfo()}>Subscribe</button>
          </div>
          <div className="footer-link">
            <div className="footer-link-list">
              {links.map((item, index) => {
                return (
                  <a href={item.href} target="_blank" key={index} >
                    <img src={item.icon} alt={item.name}/>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="footer-gitbook">
            <a href="#!">
              <p className="footer-link-copy">&copy; 2024 Starpower</p>
            </a>
            <a href='https://starpower.gitbook.io/starpower-terms-of-service/' target="_blank" >
              <p>Terms of Service</p>
            </a>
            <a href='https://starpower.gitbook.io/starpower-privacy-policy/' target="_blank" >
              <p>Privacy Policy</p>
            </a>
          </div> */}
          <div className="footer-logo">
            <img src={require('@/assets/images/home/starpower.png')} alt="logo" />
          </div>
          <div className="footer-nav">
            {isMobile&&(
              <Collapse accordion expandIconPosition="right">
                {navData.map((item,index)=>{
                  return (
                    <Panel header={item.children.children[0].children} key={index} style={customPanelStyle} onClick={()=>this.onLinkClick(item.children.href,item.children.isRedirect)}>
                      {item.subItem&&item.subItem[0].children.children.map((subItem,subIndex)=><div key={subIndex} className="footer-nav-subItem" onClick={() => this.onLinkClick(subItem.href,subItem.isRedirect)}>{subItem.children}</div>)}
                    </Panel>
                  )
                })}
              </Collapse>
            )}
            {!isMobile&&navData.map((item,index) => {
                return (
                  <div key={index} className="footer-nav-item">
                    <div className="footer-nav-text" onClick={()=>this.onLinkClick(item.children.href,item.children.isRedirect)}>{item.children.children[0].children}</div>
                    {item.subItem&&item.subItem[0].children.children.map((subItem,subIndex)=><div key={subIndex} className="footer-nav-subItem" onClick={() => this.onLinkClick(subItem.href,subItem.isRedirect)}>{subItem.children}</div>)}
                  </div>
                )
            })}
          </div>
          <div className="footer-link">
            <div className="footer-link-list">
              {links.map((item, index) => {
                return (
                  <a href={item.href} target="_blank" key={index} >
                    <img src={item.icon} alt={item.name}/>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="footer-gitbook">
            {/* <a href="#!">
              <p className="footer-link-copy">&copy; 2024 Starpower</p>
            </a>
            <a href='https://starpower.gitbook.io/starpower-terms-of-service/' target="_blank" >
              <p>Terms of Service</p>
            </a>
            <a href='https://starpower.gitbook.io/starpower-privacy-policy/' target="_blank" >
              <p>Privacy Policy</p>
            </a> */}
            © 2024 Starpower All rights reserved. 
          </div>
        </div>
      </div>
      : null
    );
  }
}

export default Footer;
